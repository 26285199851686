/* ModalForm.css */
.form-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    background-color: #f7f7f7;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    max-width: 400px;
    margin: 0 auto;
  }
  
  h2 {
    margin-bottom: 20px;
    font-size: 24px;
    color: #333;
  }
  
  form {
    display: flex;
    flex-direction: column;
  }
  
  label {
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;
    font-size: 16px;
  }
  
  input {
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 16px;
  }
  
  button {
    padding: 10px;
    background-color: #007bff;
    color: #007bff;
    border: none;
    border-radius: 5px;
    font-size: 16px;
    cursor: pointer;
  }
  
  button:hover {
    background-color: #0056b3;
  }

  .response-container {
    margin-top: 20px;
    padding: 10px;
    background-color: #f7f7f7;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    max-width: 400px;
    overflow-y: auto; /* Enable vertical scrolling if content overflows */
  }
  
  h3 {
    font-size: 20px;
    margin-bottom: 10px;
  }
  
  pre {
    white-space: pre-wrap; /* Preserve line breaks in the response text */
    font-size: 16px;
  }